import type { LinksSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import { Layout, LAYOUT_COLOR_BLOCK } from "@jobteaser/ui-kit/components/Layout/Layout"

import { CardPageLink } from "@/modules/corporate/components/CardPageLink/CardPageLink"
import { Title } from "@/modules/corporate/components/Sections/Title/Title"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./Links.module.css"

export type LinksPropsType = {
  section: LinksSection
}

export const Links: FunctionComponent<LinksPropsType> = ({ section: { title, pages } }) => {
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  return (
    <Layout colorBlock={LAYOUT_COLOR_BLOCK.GREY} hasTopPadding hasBottomPadding>
      <Layout.Column>
        {!!title && <Title title={title} tag="h2" isSmall />}
        <ul className={styles.list}>
          {pages.map(page => (
            <li key={page.title}>
              <CardPageLink
                imageDescription={(page.image.length && page.image[0].description) || ""}
                imageHeight={(page.image.length && page.image[0].height) || undefined}
                imageURL={(page.image.length && page.image[0].url) || ""}
                imageWidth={(page.image.length && page.image[0].width) || undefined}
                subtitle={page.subtitle}
                page={page}
                onClick={() =>
                  trackClickEvent("corporate_link_list", {
                    clicked_url: `/${locale}/${URL_PATHNAME}/${page.slug}`,
                    visual_context: page.title,
                  })
                }
              />
            </li>
          ))}
        </ul>
      </Layout.Column>
    </Layout>
  )
}
