import type { Page } from "@/modules/corporate/types/pages"
import type { FunctionComponent } from "react"

import cn from "classnames"
import Image from "next/image"

import IconAfterArrow from "@jobteaser/ui-kit/assets/icons/jds_afterArrow.svg"
import { Icon, ICON_SIZES } from "@jobteaser/ui-kit/components/Icon/Icon"
import { Text, TEXT_COLORS, TEXT_TYPES, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { InternalLink } from "@/modules/corporate/components/InternalLink"

import styles from "./CardPageLink.module.css"

type CardPageLinkProps = {
  imageDescription?: string
  imageHeight?: number
  imageURL: string
  imageWidth?: number
  subtitle: string
  className?: string
  page: Page
  onClick?: () => void
}

export const CardPageLink: FunctionComponent<CardPageLinkProps> = ({
  imageURL,
  imageDescription,
  imageHeight,
  imageWidth,
  subtitle,
  className,
  page,
  onClick,
}) => (
  <article className={cn(styles.main, className)}>
    {imageURL && (
      <picture className={styles.wrapperImage}>
        <Image
          src={imageURL}
          alt={imageDescription || ""}
          className={styles.image}
          height={imageHeight}
          width={imageWidth}
        />
      </picture>
    )}
    <div className={styles.content}>
      <Text
        tag={InternalLink}
        className={cn(styles.title, styles.link)}
        type={TEXT_TYPES.NORMAL}
        weight={TEXT_WEIGHTS.BOLD}
        resetSpacing
        page={{
          slug: page.slug,
          title: page.menuTitle || page.title,
        }}
        role="menuitem"
        onClick={onClick}
      />
      <Text className={styles.subtitle} type={TEXT_TYPES.SMALL} color={TEXT_COLORS.GREY} resetSpacing>
        {subtitle}
      </Text>
    </div>
    <Icon size={ICON_SIZES.MEDIUM} xlinkHref={`#${IconAfterArrow.id}`} />
  </article>
)
